<template>
  <div style="min-height: 550px" class="oter pa-6 text-center text-md-left d-flex align-center">
    <div
      style="max-width: calc(100vw - 48px)"
      class="grow"
      >
      <div 
        class="contained">
        <v-row>
          <v-col 
            cols="12" 
            md="6"
            class="d-flex flex-column justify-center"
            >
            <div class="secondary--text text-h3 font-weight-bold">Despues</div>
            <div class="font-weight-medium text-h4 mt-md-12 mt-6" style="color: #8941be">
              El evento se transforma en un documento fidedigno
            </div>
            <div class="font-weight-light mt-8 text-h4" style="color: #8941be">
              Gráficos estadisticos
            </div>

            <div class="font-weight-light mt-8 text-h4" style="color: #8941be">
              Imprimir lista de invitados
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div
              class="d-flex flex-column align-center align-md-end justify-center fill-height"
              style="gap: 24px"
              >
              <div
                class="d-flex justify-center align-center"
                >
                <v-card 
                  :width="width" 
                  class="rounded-lg"
                  >
                  <v-img
                    :width="width"
                    :src="require('@/assets/images/mobile_03.png')"
                    ></v-img
                  >
                </v-card>
              </div>
              <div 
                v-if="false"
                class="d-flex justify-center align-center" style="gap: 12px">
                <v-card
                  flat
                  height="40"
                  width="25"
                  @click="toggle('vertical')"
                  color="#8941BE66"
                  ></v-card>
                <v-card
                  flat
                  height="25"
                  width="40"
                  @click="toggle('horizontal')"
                  color="#8941BE66"
                  ></v-card>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    height: 360,
    width: 250,
  }),
  methods: {
    toggle(side) {
      if (side == "vertical") {
        this.height = 360;
        this.width = 200;
      } else if (side == "horizontal") {
        this.height = 200;
        this.width = 360;
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style></style>
