<template>
  <form @submit.prevent="procesarFormulario">
    <div style="min-height: 710px" class="outlined primary pa-6">
      <div class="contained">
        <div class="d-flex white--text text-h3 font-weight-bold justify-center text-center text-md-left">
          <div class="mt-15">Agendá una demo</div>
        </div>
        <div class="white--text text-h6 mt-4 font-weight-bold text-center">
          Completa el formulario o envianos un mail a
          <span class="secondary--text">sales@shibui.ar</span>
        </div>
        <v-spacer></v-spacer>
        <div class="mx-auto" style="max-width: 500px">
          <div class="mt-10">
            <v-text-field
              solo
              class="rounded-b-0 rounded-t-lg"
              :height="height"
              background-color="white"
              label="Nombre"
              v-model.trim="tarea.nombre"
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              solo
              :height="height"
              background-color="white"
              class="rounded-b-0 rounded-t-lg"
              label="Email"
              type="email"
              v-model.trim="tarea.email"
            ></v-text-field>
          </div>
          <div>
            <v-textarea
              solo
              class="rounded-b-0 rounded-t-lg"
              height="149px"
              label="Comentarios"
              type="text"
              v-model.trim="tarea.texto"
            ></v-textarea>
          </div>
          <div class="text-right mx-auto">
            <v-btn
              color="#8941BE"
              :disabled="bloquear"
              class="white--text px-7"
              type="submit"
              depressed
            >
              Enviar</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data: () => ({
    width: 400,
    height: 60,
    tarea: {
      texto: "",
      email: "",
      nombre: "",
    },
  }),
  methods: {
    procesarFormulario() {
      console.log(this.tarea);
      if (this.tarea.nombre.trim() === "") {
        console.log("Vacio");
        return;
      }
      console.log("No vacio");

      this.tarea = {
        texto: "",
        email: "",
        nombre: "",
      };
    },
  },
  computed: {
    bloquear() {
      return this.tarea.nombre.trim() === "" ? true : false;
    },
  },
};
</script>

<style></style>
