<template>
  <div class="white pa-6">
    <div class="contained">
      <div
        class="mt-15 d-flex justify-center primary--text text-h3 font-weight-bold text-center text-md-left"
      >
        Preguntas frecuentes
      </div>
      <v-expansion-panels
        class="mt-6"
        mandatory
        popout
        v-model="panel"
        >
        <v-expansion-panel
          v-for="(question, i) in questions"
          :key="i"
          >
          <v-expansion-panel-header
            class="text-h6"
            >
            {{ question.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ question.body }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    height: 190,
    width: 830,
    panel: null,
    questions: [
      {
        title: '¿Cómo adquiero la membresía?',
        body: 'Simplemente te ponés en contacto con nosotros y te facilitamos los datos para el pago, estés donde estés.'
      },
      {
        title: '¿Se puede trabajar con la app en 2 o más eventos en simultáneo?',
        body: 'Para nuestros primeros usuarios en registrarse en la plataforma, no existe límite de conexiones en simultáneo. Si estás leyendo esto, ¡estás de suerte!'
      },
      {
        title: '¿Qué sucede cuando se finaliza mi membresía?',
        body: 'Todos tus datos quedan resguardados en modo sólo lectura. Podés seguir accediendo a toda la información ya trabajada dentro de la plataforma, pero no podrás crear ni modificar eventos hasta que se renueve tu suscripción.'
      },
      {
        title: '¿Qué necesito para leer los códigos?',
        body: 'Un dispositivo con cámara y conexión a internet. ¡Nada más!'
      },
      {
        title: '¿Cómo comparto los códigos?',
        body: 'Cada invitación tiene una URL única que podés compartir por donde desees, ya sea WhatsApp, SMS, E-mail. ¡Incluso podés descargar el QR y hacer tu invitación personalizada!'
      },
      {
        title: '¿Cómo se cuántas personas faltan ingresar?',
        body: 'Te brindamos un contador de invitados en la misma planilla de ingresos para que no pierdas de vista ningún detalle.'
      },
      {
        title: '¿Tengo historial de ingreso?',
        body: 'Cada invitación tiene un historial independiente de ingresos y egresos, para que puedas llevar el control de los movimientos de los invitados en todo momento.'
      },
      {
        title: '¿Cómo se protegen los datos de mis invitados?',
        body: 'Toda información sensible de los invitados se encuentra encriptada dentro de la base de datos y sólo se puede acceder a la misma a través de la plataforma, de modo que si se realiza cualquier tipo de filtración no es posible acceder a ningún dato de forma externa.'
      },
      {
        title: '¿Puedo cargar foto de los invitados?',
        body: 'Para que tengas un mayor control en tus ingresos, permitimos que se realice la carga de una fotografía junto con la invitación, la cual es visible únicamente por tí.'
      },
      {
        title: '¿Puedo cambiar la fecha de un evento?',
        body: 'Todos los datos de tus eventos son editables en el momento que desees, incluida la fecha y el lugar del evento.'
      },
      {
        title: '¿Qué sucede sin cargo dos veces la misma persona?',
        body: 'Nuestro sistema detecta que existe una coincidencia entre dos invitados y se muestra una alerta en pantalla para detectar un posible error de carga de datos. De todas maneras, se permite la creación de la tarjeta puesto que no podemos detectar de forma automática si se trata de una coincidencia o de un error de carga.'
      },
      {
        title: '¿Qué significa finalizar un evento?',
        body: 'Al finalizar un evento se asume que el mismo ya ocurrió y, por lo tanto, se lo pasa a modo de sólo lectura y se deshabilitan todas las opciones administrativas. En su lugar, se brindan estadísticas del evento y se habilita la descarga de la planilla de ingresos, con el fin de tener una constancia física del flujo de invitados.'
      },
      {
        title: '¿Puedo borrar un evento finalizado?',
        body: 'Los eventos finalizados, por una cuestión de seguridad y de conservación de la información, no son eliminables. En caso de que necesites realizar una gestión fuera de esta norma te puedes poner en contacto con nosotros para que evaluemos tu caso.'
      },
    ]
  }),
};
</script>

<style></style>
