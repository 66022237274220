<template>
  <div
    class="primary"
    style="min-height: 540px; background-image: url('/image14.png'); background-position: right center; "
    :style="$vuetify.breakpoint.mdAndUp ? 'background-size: contain;' : 'background-size: cover; background-position: left center' "
    >
    <v-row class="contained">
      <v-col
        style="height: 540px"
        class="d-flex flex-column justify-center text-center text-md-left"
        cols="12"
        md="6"
      >
        <div class="pipu text-h3 font-weight-bold secondary--text">
          ¿Organizas eventos?
        </div>
        <div class="mt-10 pipu">
          <div class="text-h3 font-weight-bold white--text">Esta es tu</div>
          <div class="text-h2 font-weight-bold white--text">plataforma.</div>
        </div>
        <div
          style="width: 320px"
          class="text-h6 font-weight-regular my-8 white--text mx-auto mx-md-0"
          >
          Agendá una demo y te mostramos todas las funcionalidades y ventajas de
          la plataforma.
        </div>
        <div>
          <v-btn 
            href="https://app.flowevent.ar"
            x-large 
            color="secondary" 
            class="primary--text rounded-lg white--text py-6 py-md-3 elevation-5"
            >
            Ir a la app
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
.pipu {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
