<template>
  <v-app id="inspire">
    <v-app-bar color="#67318F" app elevate-on-scroll>
      <div style="max-width: 1200px" class="mx-auto align-center d-flex grow">
        <v-img src="imageflow.png" max-width="141px" max-height="18px"></v-img>
        <v-spacer></v-spacer>
        <v-btn 
          href="https://app.flowevent.ar"
          class="white--text" outlined>Ir a la app</v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
