<template>
  <v-footer dark padless>
    <v-col class="text-center" cols="12">
      Flowevent © {{ new Date().getFullYear() }}
      <strong>| sales@shibui.ar</strong>
    </v-col>
  </v-footer>
</template>

<script>
export default {};
</script>

<style></style>
