<template>
  <div style="min-height: 550px" class="contained white px-3 px-md-0">
    <div class="text-h3 font-weight-bold text-center mt-10 primary--text">
      Creá y gestioná tu evento
    </div>
    <div class="mt-10">
      <v-row>
        <v-col cols="12" md="4">
          <v-card outlined class="rounded-xl" height="330px">
            <div
              class="text-h4 text-center font-weight-medium primary--text mt-8"
            >
              Antes
            </div>
            <div class="mt-10">
              <div class="d-flex justify-center align-center">
                <v-card flat>
                  <v-img
                    :height="height"
                    :width="width"
                    src="lapiz.png"
                  ></v-img>
                </v-card>
              </div>
            </div>
            <div class="mx-7 mt-10 text-center text-h6" style="color: #666666">
              Crea tu evento y gestiona las invitaciones
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card outlined class="rounded-xl" height="330px">
            <div
              class="text-h4 text-center font-weight-medium primary--text mt-8"
            >
              Durante
            </div>
            <div class="mt-10">
              <div class="d-flex justify-center align-center">
                <v-card flat>
                  <v-img :height="height" :width="width" src="qr.png"></v-img>
                </v-card>
              </div>
            </div>
            <div class="mx-7 text-center mt-10 text-h6" style="color: #666666">
              Escanea los códigos de cada invitado al evento y obtén información
              en tiempo real
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card outlined class="rounded-xl" height="330px">
            <div
              class="text-h4 text-center font-weight-medium primary--text mt-8"
            >
              Despues
            </div>
            <div class="mt-10">
              <div class="d-flex justify-center align-center">
                <v-card flat>
                  <v-img
                    :height="height"
                    :width="width"
                    src="circulo.png"
                  ></v-img>
                </v-card>
              </div>
            </div>
            <div class="mx-7 mt-10 text-center text-h6" style="color: #666666">
              Visualiza las estadísticas. Envía mails a las personas que
              asistieron
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    height: 60,
    width: 60,
  }),
};
</script>

<style></style>
