<template>
  <v-container fluid class="pa-0">
    <Contenido />
    <Contenido1 />
    <Contenido2 />
    <Contenido3 />
    <Contenido4 />
    <PreguntasFrecuentes />
    <Demo />
    <Footer />
  </v-container>
</template>

<script>
import Contenido from "@/components/Contenido.vue";
import Contenido1 from "@/components/Contenido1.vue";
import Contenido2 from "@/components/Contenido2.vue";
import Contenido3 from "@/components/Contenido3.vue";
import Contenido4 from "@/components/Contenido4.vue";
import PreguntasFrecuentes from "@/components/preguntasFrecuentes.vue";
import Demo from "@/components/Demo.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Home",

  components: {
    Contenido,
    Contenido1,
    Contenido2,
    Contenido3,
    Contenido4,
    PreguntasFrecuentes,
    Demo,
    Footer,
  },
};
</script>
